<template>
  <aside>
    <header>
      <h2>Update Subscription</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <p>Here's a preview of your subscription changes:</p>

      <template v-if="error !== null">
        <p>You must <a href="#" @click.prevent="setupChannel">setup a channel</a> before upgrading your plan.</p>
      </template>
      <template v-else-if="preview !== null">
        <table class="table" v-if="switching_interval && is_trial">
          <tr v-for="line in preview.lines.data" :key="`invoiceline_${line.id}`">
            <th>
              {{$formatNumber(preview_plan.quantity)}} user{{preview_plan.quantity == 1 ? '' : 's'}} x HiThrive (at ${{$formatNumber(preview_plan.price.unit_amount/100, '0,0.00')}} / year)
            </th>
            <td>
              ${{$formatNumber(subscription_total/100, '0,0.00')}}
            </td>
          </tr>
        </table>
        <table class="table" v-else>
          <tr v-for="line in preview.lines.data" :key="`invoiceline_${line.id}`">
            <th>
              {{line.description}}
            </th>
            <td>
              ${{$formatNumber(line.amount/100, '0,0.00')}}
            </td>
          </tr>
        </table>
      </template>
      <loading-indicator v-else />

    </main>

    <footer>
      <template v-if="preview !== null">
        <div class="table-total" v-if="switching_interval && is_trial">
          <div>
            <label>Due when your trial ends ({{$formatTimestamp(subscription.trial_end)}})</label>
            <span>${{$formatNumber(subscription_total/100, '0,0.00')}}</span>
          </div>
        </div>
        <div class="table-total" v-else>
          <div v-if="switching_interval || upgrading_plan">
            <label>{{is_trial ? `Due when your trial ends (${$formatTimestamp(subscription.trial_end)})` : 'Total due today'}}</label>
            <span>${{$formatNumber(Math.max(0, (preview.total/100)), '0,0.00')}}</span>
          </div>
          <div v-else>
            <label>{{is_trial ? `Due when your trial ends (${$formatTimestamp(subscription.trial_end)})` : 'Due on your next invoice'}}</label>
            <span>${{$formatNumber(Math.max(0, (preview.total/100)), '0,0.00')}}</span>
          </div>
        </div>

        <button :class="['btn', 'btn-green', 'btn-sm', {'loading': loading}]" @click="confirmSubscription">Confirm Subscription</button>
      </template>
    </footer>
  </aside>
</template>

<script>
export default {
  name: 'SelectSubscription',
  props: ['data'],
  computed: {
    upgrading_plan() {
      return this.plan.price.metadata.HITHRIVE_PLAN_ID === '2' && this.preview_plan.price.metadata.HITHRIVE_PLAN_ID === '1';
    },
    is_trial() {
      if(this.subscription === null) return false;
      if(this.subscription.status === 'trialing') {
        return this.plan.price.metadata.HITHRIVE_PLAN_ID === this.preview_plan.price.metadata.HITHRIVE_PLAN_ID;
      }
      
      return false;
    },
    plan() {
      return this.subscription.items.data.find(si => ('HITHRIVE_PLAN_ID' in si.price.metadata) === true)
    },
    preview_plan() {
      return this.preview.subscription.items.data.find(si => ('HITHRIVE_PLAN_ID' in si.price.metadata) === true)
    },
    switching_interval() {
      if( this.subscription === null ) return true;
      return this.preview_plan.price.recurring.interval !== this.plan.price.recurring.interval;
    },
    subscription_total() {
      return this.preview_plan.price.unit_amount * this.preview_plan.quantity;
    },
  },
  data() {
    return {
      preview: null,
      subscription: null,
      loading: false,
      payment_method: null,
      new_plan: null,
      new_interval: null,
      error: null
    }
  },
  created() {
    this.payment_method = this.data.payment_method;

    this.new_plan = this.data.plan_type;
    this.new_interval = this.data.billing_interval;

    this.populateSubscriptionPreview(
      this.data.plan_type,
      this.data.billing_interval
    )
  },
  methods: {
    setupChannel() {
      this.$router.push({
        name: 'recognition'
      })
      this.$emit('done');
    },
    async submitSubscription() {
      this.loading = true;

      //try payment method
      if( this.is_trial ) {
        await this.$api.Billing.set_subscription(this.new_plan, this.new_interval)

        //give a little buffer for the webhooks to process... this isn't ideal
        setTimeout( () => {
          this.loading = false;
          this.$emit('done', 'UPDATED_SUBSCRIPTION');
        }, 2000 );
      }
      else {
        //get invoice to pay??
        const resp = await this.$api.Billing.set_subscription(this.new_plan, this.new_interval);

        //check status
        if(resp.status === 'active') {
          //give a little buffer for the webhooks to process... this isn't ideal
          setTimeout( () => {
            this.loading = false;
            this.$toast.success('Your subscription changes have been saved!');
            this.$emit('done', 'UPDATED_SUBSCRIPTION');
          }, 4000 );
        }
        else {
          //inactive subscription
          const payment_intent = resp.latest_invoice.payment_intent;
          if(payment_intent.status === 'requires_payment_method') {
            //declined card
            this.loading = false;
            this.$toast.error(
              payment_intent.last_payment_error.message
            )
          }
          else if(payment_intent.status === 'requires_action') {
            //likely 3D secure verification
            const {paymentIntent, error} = await this.$stripe.confirmCardPayment(payment_intent.client_secret);

            if( !!error ) {
              this.loading = false;
              //error
              this.$toast.error(
                error.message
              )
              return;
            }

            //give a little buffer for the webhooks to process... this isn't ideal
            setTimeout( () => {
              this.loading = false;
              this.$toast.success('Your subscription changes have been saved!');
              this.$emit('done', 'UPDATED_SUBSCRIPTION');
            }, 4000 );
            
          }
        }
      }
    },
    confirmSubscription() {
      this.loading = true;

      //do we need a credit card?
      if(this.payment_method !== null) {
        //we have a credit card

        this.submitSubscription();
      }
      else {
        //we need a payment method
        this.$root.$emit(
          'openModal',
          'Billing/SetPaymentMethod',
          {},
          (result) => {
            this.loading = false;
            console.log(result)

            if(result === 'PAYMENT_METHOD_SAVED') {
              //try subscription update
              this.submitSubscription();
            }
          }
        )
      }
    },
    async populateSubscriptionPreview(plan_type, billing_interval) {
      const resp = await this.$api.Billing.preview_subscription(plan_type, billing_interval)
      if('error' in resp) {
        this.error = resp;
        return;
      }

      this.preview = resp.preview;
      this.subscription = resp.subscription;
    },
    close() {
      this.$emit('done');
    }
  }
}
</script>

<style lang="scss" scoped>
.table-total {
  margin: 0 auto 0 0;
  > div {
    > label {
      color: $muted-text;
      margin: 0 0 5px;
      font-weight: 700;
      font-size: 12px;
      display: block;
      line-height: 12px;
    }

    > span {
      display: block;
      line-height: 16px;
    }
  }
}
</style>